import { type Component, createSignal, createEffect, Show } from "solid-js";
import { StButton } from "~/components/buttons";
import { FileUploadProgress } from "~/domains/marketing/components/FileUploadProgress";
import { usePromptContext } from "../PromptContext";

export const PastedContentUpload: Component<{
  context: "thread" | "project";
  close: () => void;
}> = (props) => {
  const { uploader } = usePromptContext();
  let textarea!: HTMLTextAreaElement;

  const [loading, setLoading] = createSignal(false);

  const uploadPastedContent = () => {
    setLoading(true);

    // File content, can be a string or a Blob (e.g., from an existing binary data).
    const fileContent = textarea.value;
    // Create a Blob object representing the file content
    const blob = new Blob([fileContent], { type: "text/plain" });

    const fileName = `Pasted ${crypto.randomUUID()}.txt`;

    const virtualFile = new File([blob], fileName, {
      type: "text/plain", // MIME type of the file
      lastModified: new Date().getTime(), // Set the last modified date
    });
    uploader.setAssets((assets) => [
      ...assets,
      uploader.newManagedAsset({
        source: fileName,
        name: fileName,
        size: blob.size,
        file: virtualFile,
      }),
    ]);

    uploader.onConfirm(props.context === "thread");
  };

  createEffect(() => {
    const assets = uploader.assets();
    if (assets.length && assets.every((a) => a.snapshot.value === "Done")) {
      props.close();
      uploader.onReset();
    }
  });

  return (
    <>
      <textarea
        disabled={loading()}
        ref={textarea}
        placeholder="Paste your knowledge as text"
        class="mb-4 bg-transparent outline-none px-3 py-2 w-full border-2 border-indigo-700 dark:border-slate-600 hover:bg-indigo-900/30 dark:hover:bg-slate-400/5 border-dashed rounded-lg"
        rows={10}
      />

      <Show when={uploader.assets()[0]} fallback={<StButton onClick={uploadPastedContent}>Upload</StButton>}>
        {(file) => <FileUploadProgress file={file()} />}
      </Show>
    </>
  );
};
